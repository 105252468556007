import { 
  QUOTE_REQUEST,
  QUOTE_SUCCESS,
  QUOTE_FAIL
 } from '../constants/quoteConstants'


export const quoteReducer =( state ={quotes:[]}, action) =>{
  switch(action.type){
    case QUOTE_REQUEST:
      return {loading:true , quotes:[]}
    
    case QUOTE_SUCCESS:
      return {loading:false, quotes:action.payload}
    
    case QUOTE_FAIL:
        return {loading:false, error:action.payload}

    default: 
         return state
  }
}