import React, {useEffect} from 'react'
import possibilityImage from '../../assets/possibility.png';
import './possibility.css';
import AOS from "aos";
import "aos/dist/aos.css";
function Possibility  () {
  useEffect(()=>{
    AOS.init({
      duration : 2000
    });
    },[])


return(
  <div className="gpt3__possibility section__padding" id="possibility">
    <div data-aos="fade-right" className="gpt3__possibility-image">
     <a href="#blog"> <img src={possibilityImage} alt="possibility" /></a>
    </div>
    <div data-aos="fade-up" className="gpt3__possibility-content">
      <h1 className="gradient__text">About <br /> Me</h1>
      <p>I am a focused, self-driven and ambitious software developer with
a growth and learning mindset seeking a fruitful career in the
Information Technology field. Competent and diligent in all aspects
of project delivery. I am currently seeking a software developer
position in a growing environment where I can contribute to
developing innovative technologies that will solve global
problems.</p>
      <h4><img src="https://img.icons8.com/external-vitaliy-gorbachev-blue-vitaly-gorbachev/60/81afdd/external-coding-online-learning-vitaliy-gorbachev-blue-vitaly-gorbachev.png"/> <br /> Coding with a purpose</h4>
    </div>
  </div>
)
}

export default Possibility