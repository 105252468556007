import React, {useState,useEffect} from 'react'
import './blog.css'
import Article from '../../components/article/Article';
import AOS from "aos";
import "aos/dist/aos.css";
function Blog() {

  const projectsData = [
    {
      title:'Aircheck',
      description:"This app helps you find your favorite new TV shows and lets you set reminders for upcoming episodes or ones that haven't aired yet. It also displays trailers for the TV shows and movies you search for. The app was built using technologies like Django, React, Nginx, Docker, Selenium, RabbitMQ, and Celery.",
      link:"https://aircheck.kagwima.com",
      src:"https://res.cloudinary.com/dcllyqc40/image/upload/v1712607988/aircheck_k5yohx.png"
    },
    {
      title:'Soko Mjini',
      description:"This is a standard E-commerce website that includes all the essential features you would expect. It was created using React for the frontend and Django for the backend. Additionally, the website features an admin panel functioning as a Content Management System (CMS). This panel allows you to track orders and manage products and users efficiently. Furthermore, the website has integrated payment functionality for seamless transactions",
      link:"https://sokomjini.kagwima.com",
      src:"https://res.cloudinary.com/dcllyqc40/image/upload/v1712607526/sokomjini_e1fcwv.png"
    },
    
    {
      title:'Git-Search',
      description:"his Web App allows a user to enter a GitHub username into a form input, submit it, and see names and descriptions of that person's public repositories. A person can also look for repositories by entering the name of the repository and submitting it and in turn get a list of repositories that match the name.This app was developed Using Angular for the front end and Django for the Backend",
      link:"https://kagus-code.github.io/Git-Search/",
      src:"https://res.cloudinary.com/dcllyqc40/image/upload/v1712609441/gitsearch_ad3pf7.png"
    },{
      title:'LightSave',
      description:" This is web application that consumes an API end point generated using Django REST framework to calculate power consumption of common house appliances and estimates the cost of running it for you.You can also add your own custom appliances",
  link:"https://kagus-code.github.io/LightSave-Angular/",
      src:"https://res.cloudinary.com/dcllyqc40/image/upload/v1624255497/LightSave_ma0aph.png"
    },
  
  ]


  useEffect(()=>{
    AOS.init({
      duration : 2000
    });
    },[])

  return (
    <div className="gpt3__blog section__padding" id="blog">
    <div className="gpt3__blog-heading">
      <h1 className="gradient__text" data-aos="fade-right">Here are, <br /> Some of my Projects.</h1>
    </div>

          <div className="gpt3__blog-container">

    
   <div  className="gpt3__blog-container_groupB">
   {projectsData.map((item,index)=>(

<a href={item.link}>  <Article className="projects" imgUrl={item.src} title={item.title} text={item.description} link={item.link} key={item.title + index}/></a>

        ))}
      </div>
    
    </div>

      
      
  </div>
  )
}

export default Blog
