import React,{useState} from 'react'
import { RiMenu3Line,RiCloseLine} from 'react-icons/ri'
import './Navbar.css'

function Navbar() {
const [toggleMenu, setToggleMenu] = useState(false)
const Menu  = () => (
<>
<p><a href="#home">Home</a></p>
{/* <p><a href="#wgpt3">What is Gpt3 </a></p> */}
<p><a href="#possibility">About</a></p>
<p><a href="#features">Skills</a></p>
<p><a href="#blog">Projects</a></p>
<p><a href="#footer">Footer</a></p>
</>

)
  return (
    <div className="gpt3__navbar">
      <div className="gpt3__navbar-links">
      <div className="gpt3__navbar-sign">
<button type='button'>kagus-code</button>
       </div>
<div className="gpt3__navbar-links_container">
<Menu/>
</div>
      </div>
       <div className="gpt3__navbar-sign">
       </div>
<div className="gpt3__navbar-menu" >

{toggleMenu ? 
  <RiCloseLine color="#fff" size={27} onClick={() => setToggleMenu(false)} />
:<RiMenu3Line color="#fff" size={27} onClick={() => setToggleMenu(true)} />
}
{toggleMenu && (

  <div className="gpt3__navbar-menu_container scale-up-center">
    <div className="gpt3__navbar-menu_container-links">
    <Menu/>
    <div className="gpt3__navbar-menu_contaiainer-links-sign">

       </div>
    </div>

  </div>
)}
</div>

    </div>
  )
}

export default Navbar
