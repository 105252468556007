import React, {useEffect} from 'react'
import './Brand.css'
import AOS from "aos";
import "aos/dist/aos.css";

function Brand() {

  useEffect(()=>{
    AOS.init({
      duration : 2000
    });
    },[])
  return (
    <div data-aos="fade-up" className='gpt3__brand section__padding'>
      <div>
      <a href="https://github.com/kagus-code"  rel="noopener noreferrer" target="_blank"><img src="https://img.icons8.com/ios/50/81afdd/github.png"/></a>
      </div>
      <div>     
     <a href="https://www.linkedin.com/in/eston-kagwima/" target="_blank"><img src="https://img.icons8.com/ios/50/81afdd/linkedin-circled.png"/></a>    
      </div>
      <div>     
      <a href="mailto:kagwimaeston@gmail.com"><img src="https://img.icons8.com/ios/50/81afdd/apple-mail.png"/></a>
     </div>
      <div>     
      <a href="https://drive.google.com/file/d/1Y4U8HDX4L6uqx7AiI1SX0hl2QxQV_LDe/view?usp=drive_link"target="_blank"><img src="https://img.icons8.com/ios/50/81afdd/parse-from-clipboard.png"/></a>         
     </div>
    </div>
  )
}

export default Brand
