import React, {useState,useEffect} from 'react'
import './Article.css'
import AOS from "aos";
import "aos/dist/aos.css";

function Article({ imgUrl, title, text,link }) {



  useEffect(()=>{
    AOS.init({
      duration : 2000
    });
    },[])
  


  return (
    <div data-aos="fade-up"
    data-aos-duration="3000" className="gpt3__blog-container_article">
    <div className="gpt3__blog-container_article-image">
      <img style={{height: "300px"}} src={imgUrl} alt="blog_image" />
    </div>
    <div className="gpt3__blog-container_article-content">
      <div>
        <h3>{title}</h3>
        <p>{text}</p>
      </div>
      <button><a href={link} target="_blank">view site</a></button>
    </div>
  </div>
  )
}

export default Article
