import React from 'react'
import './feature.css'
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import Radium, { StyleRoot } from 'radium';
function Feature({src,value,tech}) {

  const style = {
 
    width: 40,
     marginTop: -5,
    // Adding media query..
    '@media (max-width: 500px)': {
      display: 'none',
    },
  };
  return (
    <div className='gpt3__features-container__feature'>
<StyleRoot>
 <CircularProgressbarWithChildren value={value} >
  {/* Put any JSX content in here that you'd like. It'll be vertically and horizonally centered. */}
  <img  style={style} src={src} alt="doge" />
  <div style={{ fontSize: 12, marginTop: 2 ,}}>
    <strong className='gpt3__features-container_feature-text'>{value}%</strong> 
  </div>
  <strong  style={{  marginLeft: 10,marginTop: 2, }}>{tech}</strong> 

</CircularProgressbarWithChildren>

</StyleRoot>
 
 <div className='gpt3__features-container_feature-text'>

 </div>
    </div>
  )
}

export default Feature
