import axios from 'axios'
import { QUOTE_REQUEST,QUOTE_SUCCESS,QUOTE_FAIL } from '../constants/quoteConstants'


export const listQuotes = ()=> async (dispatch)=>{

  try{
    dispatch({type:QUOTE_REQUEST})
    const {data} = await axios.get('https://api.quotable.io/random')
    dispatch({
      type:QUOTE_SUCCESS,
      payload:data
    })

  }catch(error){
    dispatch({
      type:QUOTE_FAIL,
      payload:error.response
    })
  }

}

