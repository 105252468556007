import React, { useState, useEffect } from 'react';
import './features.css';
import { useDispatch, useSelector } from 'react-redux';
import { Feature, Loader } from '../../components/';
import { listQuotes } from '../../actions/quoteAction';
import AOS from 'aos';
import 'aos/dist/aos.css';

function Features() {
  const featuresData = [
    {
      tech: 'Python',
      value: 80,
      src: 'https://img.icons8.com/color/50/81afdd/python--v1.png',
    },
    {
      tech: 'Javascript',
      value: 70,
      src: 'https://img.icons8.com/color/48/81afdd/javascript--v1.png',
    },
    {
      tech: 'HTML',
      value: 90,
      src: 'https://img.icons8.com/color/48/81afdd/html-5--v1.png',
    },
    {
      tech: 'CSS',
      value: 90,
      src: 'https://img.icons8.com/color/48/81afdd/css3--v1.png',
    },
    {
      tech: 'Django',
      value: 90,
      src: 'https://img.icons8.com/color/48/FFFFFF/django--v1.png',
    },
    {
      tech: 'Flask',
      value: 80,
      src: 'https://img.icons8.com/cute-clipart/64/FFFFFF/flask.png',
    },
    {
      tech: 'React',
      value: 80,
      src: 'https://img.icons8.com/office/50/000000/react.png',
    },
    {
      tech: 'Bootstrap',
      value: 70,
      src: 'https://img.icons8.com/color/48/81afdd/bootstrap--v1.png',
    },
    {
      tech: 'PostgreSQL',
      value: 90,
      src: 'https://img.icons8.com/color/48/81afdd/postgreesql--v1.png',
    },
    {
      tech: 'Git',
      value: 80,
      src: 'https://img.icons8.com/color/48/81afdd/git.png',
    },
    {
      tech: 'Docker',
      value: 80,
      src: 'https://img.icons8.com/color/48/cdYUlRaag9G9/docker.png',
    },
  ];

  const dispatch = useDispatch();
  const quoteList = useSelector((state) => state.quoteList);
  const { error, loading, quotes } = quoteList;

  const steveJobsQuote = "The people who are crazy enough to think they can change the world are the ones who do.";

  useEffect(() => {
    dispatch(listQuotes());
    AOS.init({
      duration: 2000,
    });
  }, [dispatch]);

  const clickHandler = (e) => {
    e.preventDefault();
    if (!error) {
      dispatch(listQuotes());
    }
  };

  return (
    <div className="gpt3__features section__padding" id="features">
      {loading ? (
        <Loader />
      ) : (
        <div onClick={clickHandler} data-aos="fade-right" className="gpt3__features-heading">
          <h1 className="gradient__text">{error ? steveJobsQuote : quotes.content}</h1>
          <div className="arrow"></div>
          <p>Author: {error ? "Steve Jobs" : quotes.author}</p>
        </div>
      )}
      <div data-aos="fade-up" className="gpt3__features-container">
        {featuresData.map((item, index) => (
          <div style={{ width: '25%' }} key={item.tech + index}>
            <Feature src={item.src} tech={item.tech} value={item.value} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default Features;
