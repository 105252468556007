import React, { useEffect,  } from 'react';import './Cta.css'

function Cta() {


  useEffect(() => {
    const button = window.PayPal.Donation.Button({
        env: 'production',
        hosted_button_id: 'NDP54TL98A85L',
        image: {
            src: 'https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif',
            alt: 'Donate with PayPal button',
            title: 'PayPal - The safer, easier way to pay online!',
        }
    });
    button.render('#donate-button'); 
}, []);


  return (
    <div className="gpt3__cta">
    <div className="gpt3__cta-content">
      <h3>Would you like to Buy me a Caffeinated Beverage?</h3>
      <p><img src="https://img.icons8.com/color/48/81afdd/energy-drink.png"/></p>
    </div>
    <div className="gpt3__cta-btn" id='donate-button'>
    </div>
  </div>
  )
}

export default Cta
