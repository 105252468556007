import React from 'react'
import './header.css'
import Typed from 'typed.js';
import { useState, useEffect, useRef } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

function Header() {
  const typedRef = useRef(null)
  const [message, setMessage] =  useState('')
  useEffect(()=>{
    const typed = new Typed(typedRef.current,{
      strings: ['A Software Developer', "An Economist", "A Crypto Enthusiast", "And a Gamer"],
      typeSpeed : 150,
      backSpeed: 60,
      loop: true,
      showCursor:false
    });

    AOS.init({
      duration : 2000
    });
  },[])

  const submitHandler = (e) => {
    e.preventDefault()
   let text = encodeURI(message)


   if (window.innerWidth <= 480 || window.innerHeight <= 480) {
    window.open(` https://wa.me/254701655877?text=${text}
   `)
    
}
window.open(`https://web.whatsapp.com/send?phone=254701655877&text=${text}`)
    
  
 
  }

  return (
    <div className="gpt3__header section__padding" id='home'>
      <div className="gpt3__header-content">
      <h2 >Hello, my name is</h2>
        <h1 className="gradient__text"> Eston Kagwima.</h1>
        <h2> &nbsp;<span ref={typedRef}></span></h2>
        <div className='gpt3__header-content__input'>
    <form onSubmit={submitHandler}>      
<textarea 

required
type='text' 
value={message}
onChange={(e) => setMessage(e.target.value)}
placeholder="Your message"/>

<button type='submit'>Message me </button>
</form>

        </div>
        <div className='gpt3__header-content__people'>
        <img src="https://img.icons8.com/external-flaticons-lineal-color-flat-icons/64/81afdd/external-hobbies-quarantine-flaticons-lineal-color-flat-icons.png"/><p>Developing cool stuff !!</p>
        </div>

        
      </div>


<div data-aos="fade-left" className='gpt3__header-image'>
          <img src="https://res.cloudinary.com/dcllyqc40/image/upload/v1685653113/me1_mg6km4.png" alt="ai" />
        </div>
    </div>
  )
}

export default Header
