import React, {useEffect} from 'react'
import './App.css'
import { Blog, Footer, Possibility, Features, Header, } from './containers'
import { Cta, Brand, Navbar } from './components'



function App() {



  return (
    <div className="App">
      <div className="gradient__bg">
        <Navbar />
        <Header />
      </div>
      <Brand />

      <Possibility />
      <Features />
      <Cta />
      <Blog />
      <Footer />
    </div>
  );
}

export default App;
